/* Navbar.module.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
  }
  
  .logo a {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
  }
.powered{
  margin-left: 10px;
}

.userimg{
  width: 40px;
  border-radius: 50%;
  margin-top: 10px;
}

  .logo img{
    width: 10%;
    border-radius: 10px;
  }
  
  .navLinks {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;

  }
  
  .navLinks li {
    margin-left: 20px;
    cursor: pointer;
  }

  .navLinks .loginbtn{
    position: absolute;
    margin: 10px 70px 10px -40px;  
  }
  
  .navLinks a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }
  
  .navLinks a:hover {
    text-decoration: underline;
  }

  .dropdown-menu {
  position: absolute;
  /* top: 100%; */
  z-index: 999;
  width: 200px;
  background-color: #fffcfc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  margin-left: -130px;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.dropdown-menu img{
  width: 80px;
  margin: auto;
}

  .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
  }

  .logout-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    text-decoration: underline;
  }

  .nettzero-logo {
    width: 130px; /* Adjust size as needed */
    height: auto;
    margin-left: 8px; /* Space between the text and the image */
    border-radius: 10px;
    vertical-align: middle;
  }
  
  
  /* Responsive design */
  @media (max-width: 768px) {
    .logo img{
      width: 25%;
      border-radius: 10px;
    }
    .user-name{
      font-size: 13px;
    }
  
    .navLinks {
      width: 100%;
      flex-direction: row
    }
  
    .navLinks li {
      margin: 10px 0;
      margin-left: 0;
    }

   .navLinks li{
    margin-left: 20px;
   }

   .userimg{
    width: 35px;
    margin-left: -40px;
    border-radius: 50%;
    margin-top: 4px;
  }

   /* .powerclass{
    margin-top: -20px !important;
   } */

   .powered{
    font-size: 10px;
    margin-left: -2px;
   }

   .nettzero-logo{
    width: 120%;
    margin-left: -2px;

   }
  }
  