/* home.css */
:root {
    --primary-color: #00695c; /* Deep teal */
    --secondary-color: #00796b; /* Slightly lighter teal */
    --accent-color: #fbc02d; /* Mustard yellow for highlights */
    --background-color: #f5f5f5; /* Light gray for background */
    --text-color: #333; /* Dark gray for text */
  }

  
  .home-container {
    padding: 20px;
    max-width: 80%;
    margin: 0 auto;
  }
  
  .home-header {
    text-align: center;
    padding: 40px 20px;
    background-color: var(--primary-color);
    color: white;
    margin-bottom: 30px;
    border-radius: 8px;
  }
  
  .home-header h1 {
    margin: 0;
    font-size: 2.5rem;
  }
  
  .home-header p {
    font-size: 1.2rem;
    color: rgb(179, 245, 233);

  }
  
  .home-section {
    margin-bottom: 40px;
  }
  
  .home-section h2 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .categories,
  .featured-tools {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
  }
  
  .category-card,
  .tool-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    flex: 1;
    min-width: 250px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .category-card:hover,
  .tool-card:hover {
    transform: translateY(-5px);
  }
  
  .tool-card img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .home-cta {
    text-align: center;
    background-color: var(--secondary-color);
    padding: 40px 20px;
    color: white;
    border-radius: 8px;
  }
  
  .home-cta h2 {
    margin: 0 0 20px;
  }
  
  .cta-button {
    background-color: var(--accent-color);
    color: var(--text-color);
    padding: 15px 30px;
    font-size: 1rem;
    border: none;
    width: 40%;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #f5a623;
  }

.registerform {
  margin: 30px auto;
  padding: 20px;
  width: 80%;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.registerform h2 {
  text-align: center;
  font-size: 26px;
  color: #3a6ea5;
}

.membership-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.membership-form label {
  font-size: 18px;
  color: #555;
}

.membership-form input,
.membership-form select,
.membership-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.membership-form input:focus,
.membership-form select:focus,
.membership-form textarea:focus {
  outline: none;
  border-color: #3a6ea5;
}

.membership-form button {
  padding: 15px;
  background-color: #3a6ea5;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.membership-form button:hover {
  background-color: #2a5d94;
}


/* Responsive Design */
@media (max-width: 768px) {
  .home-container {
    padding: 10px;
    max-width: 100%;
  }
  
  .home-header {
    padding: 20px 10px;
  }
  
  .home-header h1 {
    font-size: 1.5rem;
  }

  .home-header p {
    font-size: 0.8rem;
  }

  .home-section h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .categories,
  .featured-tools {
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .category-card,
  .tool-card {
    min-width: 90%; /* Allowing them to take up more horizontal space on mobile */
    padding: 15px;
  }

  .tool-card img {
    max-width: 100%;
    height: auto;
  }

  .home-cta {
    padding: 30px 10px;
  }

  .cta-button {
    padding: 10px 20px;
    width: 80%; /* Full width for mobile */
  }

  /* Register Form */
  .registerform {
    width: 90%;
    padding: 15px;
    margin: 20px auto;
  }

  .registerform h2 {
    font-size: 22px;
  }

  .membership-form label {
    font-size: 16px;
  }

  .membership-form input,
  .membership-form select,
  .membership-form textarea {
    font-size: 14px;
    padding: 8px;
  }

  .membership-form button {
    font-size: 16px;
    padding: 12px;
  }

  /* Reducing the overall spacing */
  .home-header,
  .home-section,
  .home-cta {
    margin-bottom: 20px;
  }
}
