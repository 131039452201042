.all-items-container {
    padding: 20px;
  }
  
  .items-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.item-card {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    text-align: left;
    background-color: #f9f9f9;
  }

  .loader {
    text-align: center;
    font-size: 1.5em;
    margin: 20px;
  }
  
  .item-image {
    width: 96%;
    height: 280px;
    border: 1px solid black;
    /* border: 1px solid rgb(224, 217, 217); */
    padding: 5px;
    border-radius: 18px;
    margin-bottom: 10px;
  }
  
  .item-link {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  


  /* Mobile view adjustments */
@media (max-width: 768px) {
  .items-grid {
    grid-template-columns: repeat(2, 1fr); /* Show 2 items per row */
    gap: 15px;
  }

  .item-card {
    padding: 12px;
  }

  .item-image {
    height: 220px; /* Reduce image height for smaller screens */
  }
}

@media (max-width: 480px) {

  .loader img{
    width: 100%;
  }

  .items-grid {
    grid-template-columns: 1fr; /* Show 1 item per row */
    gap: 10px;
  }

  .item-card {
    padding: 10px;
    text-align: center; /* Center content for a cleaner look on small screens */
  }

  .item-image {
    width: 95%; /* Full width for images */
    height: 280px;
  }

  .item-link {
    margin-top: 5px;
    font-size: 0.9em; /* Smaller font for links */
  }

  .loader {
    font-size: 1.2em; /* Adjust loader font size */
  }
}