/* Dashboard Header */
.dashboard-header {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background-color: #f1f1f1; /* Ensure consistent background */
  }

  Link{
    text-decoration: none;
  }
  
  /* Logout Button */
  .logout-button {
    background-color: #023e8a;
    color: #fff;
    padding: 10px 20px;
    width: 100px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;
  }
  
  .logout-button:hover {
    background-color: #03045e;
    transform: scale(1.05);
  }

  .category-section{
    padding: 0px 5px;
  }

  /* Category Buttons */
  .category-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 70%;
    background-color: rgb(52, 51, 51);
    padding: 10px 10px 40px;
    margin: -70px 150px;
  }

  .seperatecategorydiv{
    width: 100%;
    background-color: rgb(52, 51, 51);
  }
  
  .category-button {
    background-color: #0096c7;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease, transform 0.2s;
  }
  
  .category-button:hover {
    background-color: #005f73;
    transform: scale(1.05);
  }
  
  .category-button.active {
    background-color: #023e8a;
  }

  .horiimage img {
    width: 100%; /* Makes the image width cover the container */
    height: 450px; /* Sets a fixed height for the image */
    object-fit: cover; /* Ensures the image fills the container without stretching */
    border-radius: 10px; /* Optional: Rounds the corners of the image */
    display: block; /* Removes the bottom space under the image (inline elements issue) */
    margin-top: -14px;
  }
  
  
  /* Subcategory Buttons */
  .subcategory-buttons {
    display: flex;
    justify-content: center; /* Centers the buttons horizontally */
    gap: 10px; /* Space between the buttons */
    margin: -40px auto; /* 20px margin top and bottom, auto centers horizontally */
    width: 90%; /* Ensures the buttons take only as much space as needed */
  }
  
  .category-header{
    padding: 10px;
    background-color: rgb(224, 224, 224);
    border-radius: 10px;
  }

  .category-header h2{
    margin-top: -6px;
  }

  .subcategory-button {
    background-color: #e0f7fa;
    color: #00796b;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 0.85rem;
    transition: background-color 0.3s ease, transform 0.2s;
  }
  
  .subcategory-button:hover {
    background-color: #b2dfdb;
    transform: scale(1.05);
  }
  
  .subcategory-button.active {
    background-color: #00796b;
    color: #fff;
  }
  
  /* Data List Grid */
  .data-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    margin: 0 auto;
    max-width: 90%;
  }
  
  /* Data Item Card */
  .data-item {
    text-decoration: none;
    /* background-color: #ffffff; */
    border-radius: 8px;
    padding: 20px;
    transition: transform 0.2s, box-shadow 0.2s;
  }

  .data-image {
    width: 90%;
    max-width: 400px; /* Set max-width to control image size */
    height: auto;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid black;
  }
  
  
  .data-item h3 {
    font-size: 1.2rem;
    text-align: left;
    margin-bottom: 10px;
    color: #0077b6;
  }
  
  .data-item p {
    width: 100%;
    font-size: 0.95rem;
    text-align: justify; /* Justifies the text content */
    color: #2c2b2b;
    background-color: rgb(224, 224, 224);
    margin: 0; /* Optional: Removes default margin for more control */
    padding: 6px; /* Optional: Adds some padding for better readability */
    border-radius: 6px;
  }
  

  .cms-data-thumbnail {
    margin-right: 10px;
  }
  
  .cms-data-file-link {
    display: block;
    margin-bottom: 5px;
  }
  
  .cms-data-video-link {
    display: block;
    margin-bottom: 5px;
  }
  
  .cms-selected-image {
    margin: 5px;
    max-width: 150px;
    height: auto;
  }

  .category-buttons,
.subcategory-buttons {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.category-buttons button,
.subcategory-buttons button {
  padding: 10px 15px;
  cursor: pointer;
}

.subcategory-description{
  font-size: larger;
}

.subcategory-description {
  pointer-events: none; /* Disables any hover effects */
}

.image-scroll {
  display: flex;
  overflow-x: auto;
  border-radius: 10px;
  gap: 10px;
  padding: 10px 0;
  scrollbar-width: none; /* Firefox */
  background-color: #f6ebe0;
  margin-bottom: 20px;
  margin-bottom: 30px;

}

.image-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.data-item {
  width: 20%; 
  flex: 0 0 auto;
  text-align: center;
  margin-top: -35px;
}

.data-item img {
  width: 100%;
  height: 300px;
  /* border: 1px solid rgb(224, 217, 217); */
  padding: 5px;
  border-radius: 18px;
  margin-bottom: 10px;
}

.see-all-button {
  font-size: 1rem;
  position: absolute;
  color: #007bff;
  text-decoration: none;
  /* border: 1px solid #007bff; */
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 22%;
  margin-top: -30px;
  /* background-color: white; */
}

.categorydiv{
margin-top: 7px;
border-radius: 10px;
}

.category-section {
  margin-bottom: -20px;
}


.category-section .subcategory-description {
  margin-top: 10px;
  margin-left: 20px;
  /* margin-bottom: -30px; */
} 

.category-section h2 {
  margin-bottom: 0px;
  margin-left: 20px;
}

.more-link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}


.dltbtn{
  background-color: rgb(229, 81, 81);
}


.logout-button {
  padding: 10px 20px;
  background-color: #f44336;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

/* //////paid tools css */

/* Container for Paid Tools Content */
.paid-tools-content {
  display: flex; /* Aligns the child elements side by side */
  justify-content: space-between; /* Adds space between the sections */
  gap: 20px; /* Adds a gap between the sections */
  margin: 20px auto; /* Adds some margin around the container */
  width: 90%;
}

/* Each Paid Tools Section */
.paid-tools-section {
  background-color: #f8f9fa; /* Light background color for contrast */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside each section */
  width: 48%; /* Adjust width to take equal space */
  text-align: center; /* Center-aligns text and images */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  margin-top: -10px;
}

.paid-tools-section:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

/* Headings in Paid Tools Section */
.paid-tools-section h2 {
  font-size: 30px; /* Adjusted font size */
  margin-bottom: 15px; /* Spacing below the heading */
  margin-top: -20px;
  text-align: left;
  color: #333; /* Dark text color */
}

/* Images in Paid Tools Section */
.paid-tools-section img {
  max-width: 400px; /* Ensures the image is responsive */
  border-radius: 4px; /* Slight rounding for image corners */
  border: 1px solid black;
}

/* Anchor Links in Paid Tools Section */
.paid-tools-section a {
  text-decoration: none; /* Removes default underline from links */
  color: inherit; /* Inherits text color from the parent */
  display: block; /* Ensures the image is clickable as a block */
}
.subclass{
  display: flex;
  justify-content: space-between;
}

.calculate-button {
  background-color: #0254ac; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Removes default border */
  padding: 5px 10px; /* Button padding */
  border-radius: 4px; /* Rounded corners */
  width: 350px;
  cursor: pointer; /* Pointer cursor on hover */
}

.calculate-button:hover {
  background-color: #024285; /* Darker shade on hover */
}


.buttonsdiv {

  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Keeps the grid layout */
  gap: 3px;
  position: absolute; /* Allows positioning relative to the nearest positioned ancestor */
  right: 10px; /* Aligns the div to the right side */

}


.Community {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9; /* Light gray background for contrast */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  margin: auto;
}


.Community p {
  font-size: 36px;
  color: #555; /* Medium gray text */
  margin-bottom: 20px;
  line-height: 1.6; /* Improve readability */
}

.Community a {
  display: inline-block;
  text-decoration: none;
  margin-top: 10px;
}

.Community img {
  width: 400px; /* Fixed width for the logo */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

.Community img:hover {
  transform: scale(1.1); /* Slight zoom on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Add a shadow effect */
}


 
/* /////// */


/* Reports CSS */

.reports-section {
  margin: 20px;
  text-align: center;
}

.report-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.download-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.download-button:hover {
  background-color: #0056b3;
}
 /* /////////////////////// */


 .coming-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 40px;
  background: linear-gradient(135deg, #f7f7f7, #eaeaea);
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  max-width: 600px;
  text-align: center;
}

.coming-soon h2 {
  font-size: 46px;
  color: #333;
  margin-bottom: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.coming-soon p {
  font-size: 40px;
  color: #666;
  margin-top: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.coming-soon p::before {
  font-size: 24px;
}


.coming-soon p::after {
  font-size: 24px;
}

@media (max-width: 768px) {
  .coming-soon {
    padding: 30px;
  }

  .coming-soon h2 {
    font-size: 28px;
  }

  .coming-soon p {
    font-size: 18px;
  }
  .category-button.community-button {
    display: none;
  }
  #categorybuttons button{
    width: 100%;
  }

}

@media (max-width: 1208px) {

  .category-button.community-button {
    display: none;
  }

  #categorybuttons button{
    width: 18%;
  }

}


html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}


/* Responsive Adjustments */
@media (max-width: 1068px) {
  .data-list {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 10px;
  }

  h1 {
    font-size: 1.5rem;
  }
  
  #categorybuttons button{
    width: 100%;
  }

  .category-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 65%;
    background-color: rgb(52, 51, 51);
    padding: 10px 10px 60px;
    margin: -70px auto auto ;
  }
  
}

/* @media (max-width: 480px) {

  .dashboardmain{
    padding: 5px 2px;
    font-size: 10px;
  }
  
  #categorybuttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  #subcategorybuttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .logout-button {
    font-size: 0.85rem;
    padding: 8px 16px;
  }

  .category-button, .subcategory-button {
    font-size: 0.85rem;
    padding: 8px 16px;
  }
} */


/* General Mobile Styles */
@media (max-width: 768px) {
  
  /* Dashboard Header */
  .dashboard-header {
    justify-content: space-between;
    padding: 10px;
  }
  
  /* Category Section */
  .category-section {
    padding: 5px;
    margin-bottom: 0;
  }

  .category-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
    margin: -30px auto 20px 0px;
    gap: 10px;
    padding: 10px;
  }

  .category-buttons button {
   width: 100%;
  }

  .seperatecategorydiv {
    width: 100%;
    padding: 10px;
  }

  .horiimage img {
    height: auto;
    max-height: 300px;
  }

  /* Subcategory Buttons */
  .subcategory-buttons {
    /* flex-direction: column; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
    margin-top: -20px;
    gap: 10px;
  }

  .subcategory-buttons button{
    width: 100%;
  }

  .category-header {
    padding: 5px;
  }

  /* Data List Grid */
  .data-list {
    max-width: 50%;
    padding: 10px;
    gap: 15px;
  }

  /* Data Item Card */
  .data-item {
    width: 100%;
    margin-top: 0;
  }

  .data-image {
    width: 100%;
  }

  /* Paid Tools Section */
  .paid-tools-content {
    /* flex-direction: column; */
    width: 100%;
    margin-top: 10px;
  }

  .paid-tools-section {
    width: 100%;
    padding: 10px;
  }

  .paid-tools-section img {
    max-width: 100%;
  }

  /* Buttons */
  .category-button, .subcategory-button, .calculate-button {
    width: 80%;
    font-size: 0.6rem;
  }

  /* Logout Button */
  .logout-button {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    margin-top: 10px;
  }

  /* Image Scroll */
  .image-scroll {
    /* padding: 10px 0; */
    height: fit-content;
    gap: 10px;
    margin-bottom: 10px;
  }

  .data-item {
    margin-top: -30px;
    width: 60%;
  }

  .data-item h3{
font-size: medium;
  }

  .data-item img {
    height: 50%;
  }
  .data-item p {
    width: 100%;
  } 
  /* Category Section */
  .category-section h2, .category-section .subcategory-description {
    margin-left: 10px;
  }

  .category-section .subcategory-description {
    font-size: medium;
  }

  .see-all-button {
    margin-left: 80%;  
  }
 
  .category-section div {
    padding-top: 10px;
  }

  .subclass{
    font-size: 20px;
  }

  .calculate-button{
    width: 50%;
  }

  .categorydiv {
    margin-top: 15px;
    border-radius: 8px;
  }

  .paid-tools-section{
margin-top: 20px;
padding-top: 30px;
  }

  .horiimage img {
    max-height: 250px; /* Limit the height of the image */
  }

  .paid-tools-section h2 {
    font-size: 0.7em; /* Further reduce font size on small screens */
  }

  .paid-tools-section a img {
    width: 120px; /* Make images fully responsive on small screens */
    height: 30px;
    max-width: none; /* Remove max-width restriction */
  }
}
